import React, { Suspense, useState, useEffect } from 'react';
import './index.css';
import { initMiniApp } from '@telegram-apps/sdk';
import { setDebug } from '@telegram-apps/sdk';
import { retrieveLaunchParams } from '@telegram-apps/sdk';
import { mockTelegramEnv, parseInitData } from '@telegram-apps/sdk';


const TeleGramApp = React.lazy(() => import('./TeleGramApp'));

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    console.error("Error caught by ErrorBoundary:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <div className="bg-red-500 text-white p-2 text-center">{this.state.error.message}</div>;
    }
    return this.props.children;
  }
}

function Page() {
  return (
    <div>
      <Suspense
        fallback={
          <div className="flex justify-center items-center h-screen">
            <div className="w-16 h-16 border-4 border-transparent border-t-4 border-blue-500 rounded-full animate-spin loader"></div>
          </div>
        }>
        <TeleGramApp />
      </Suspense>
    </div>
  );
}

function App() {

  const initDataRaw = new URLSearchParams([
    ['user', JSON.stringify({
      id: 99281932,
      first_name: 'Andrew',
      last_name: 'Rogue',
      username: 'rogue',
      language_code: 'en',
      is_premium: true,
      allows_write_to_pm: true,
    })],
    ['hash', '89d6079ad6762351f38c6dbbc41bb53048019256a9443988af7a48bcad16ba31'],
    ['auth_date', '1716922846'],
    ['start_param', 'debug'],
    ['chat_type', 'sender'],
    ['chat_instance', '8428209589180549439'],
  ]).toString();
  
  mockTelegramEnv({
    themeParams: {
      accentTextColor: '#6ab2f2',
      bgColor: '#17212b',
      buttonColor: '#5288c1',
      buttonTextColor: '#ffffff',
      destructiveTextColor: '#ec3942',
      headerBgColor: '#17212b',
      hintColor: '#708499',
      linkColor: '#6ab3f3',
      secondaryBgColor: '#232e3c',
      sectionBgColor: '#17212b',
      sectionHeaderTextColor: '#6ab3f3',
      subtitleTextColor: '#708499',
      textColor: '#f5f5f5',
    },
    initData: parseInitData(initDataRaw),
    initDataRaw,
    version: '7.2',
    platform: 'android',
  });
  setDebug(true);
  const [isAndroid, setIsAndroid] = useState(null);
  const [LaunchParams, setLaunchParams] = useState(null);
  const [error, setError] = useState(null);
  const [miniApp] = initMiniApp();


  useEffect(() => {
    const initializeApp = async () => {
      try {
        const data = retrieveLaunchParams();
        setLaunchParams(data);
        setIsAndroid(data.platform === 'android');
      } catch (err) {
        setError(err.message || 'An unknown error occurred');
      }
    };

    initializeApp();
  }, []);

  if (isAndroid === null) {
    return (
      <>
        <div className="flex justify-center items-center h-screen">
          <div className="w-16 h-16 border-4 border-transparent border-t-4 border-blue-500 rounded-full animate-spin loader"></div>
        </div>
      </>
    );
  }

  miniApp.ready();
  return (
    <ErrorBoundary>
      {
        !isAndroid ? (
          <div className="bg-red-500 text-white p-2 text-center">
            This app is not supported on {LaunchParams.platform}
          </div>
        ) : (
          <Page />
        )
      }
    </ErrorBoundary>
  );
}

export default App;
